import { redirect } from 'react-router-dom'
import { getCurrentUser } from './api'

export async function redirectToNewsLoader() {
  try {
    const currentUser = await getCurrentUser()
    if (!currentUser) {
      throw new Error('Unauthenticated')
    }
    const firstPropertyId = currentUser.ownedProperties[0]?.id || currentUser.livingProperty?.id
    if (firstPropertyId) {
      throw new Error(`/${firstPropertyId}/news`, { cause: 'redirect-to-news' })
    }
    return null
  } catch (err) {
    if (err instanceof Error) {
      if (err.cause === 'redirect-to-news') {
        throw redirect(err.message)
      }
      if (err.message === 'Unauthenticated') {
        const token = localStorage.getItem('token')
        const refreshToken = localStorage.getItem('refreshToken')
        localStorage.clear()
        throw redirect(`/auth/login${token || refreshToken ? '?error=session-expired-please-login-again' : ''}`)
      }
    }
    console.error('Error:', 'redirectToNewsLoader.ts', err)
    return null
  }
}
